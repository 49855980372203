
























































import { Component, Prop } from 'vue-property-decorator';
import PillWidget from '@/components/pill/PillWidget.vue';
import TitleComponent from '@/components/TitleComponent.vue';
import { Data } from '@/utils/types/WidgetData';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';

@Component({
  components: {
    PillWidget,
    TitleComponent,
  },
  inheritAttrs: false,
})
export default class TabItemListWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  private selectedTab = -1;

  private isReadyToShow = false;

  private loadedWidgets: string[] = [];

  private subWidgets: Data[] = [];

  private hiddenWidgets: string[] = [];

  private widgetHidden = false;

  private get tabs(): Data[] {
    if (this.widget && this.widget.subWidgets && this.widget.subWidgets.length > 0) {
      return this.widget.subWidgets
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          const preload = payload.preload === undefined ? true : payload.preload;
          return { uid: sub.uid, ...payload, preload };
        });
    }
    return [];
  }

  mounted(): void {
    if (this.widget && this.widget.subWidgets && this.widget.subWidgets.length > 0) {
      this.subWidgets = this.widget.subWidgets
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          const preload = payload.preload === undefined ? true : payload.preload;
          return { uid: sub.uid, ...payload, preload };
        });
    }
    this.setSelectedTab();
  }

  created(): void {
    this.$root.$on('hide-pill', this.onHidePill);
    this.setDataConfig();
  }

  setSelectedTab(): void {
    if (this.isReadyToDisplay && this.$el) {
      const tabsElement = this.$refs.tab_list as HTMLElement;
      if (tabsElement && tabsElement.childNodes) {
        tabsElement.childNodes.forEach((el, i) => {
          const { classList } = el as HTMLElement;
          if (el.nodeType !== Node.COMMENT_NODE) {
            if (this.selectedTab === -1) {
              this.selectedTab = i;
            }
            if (!this.isReadyToShow) {
              this.isReadyToShow = true;
            }
            if (i === this.selectedTab) {
              this.setTabItemData(classList);
              (el as HTMLElement).classList.remove('d-none');
            } else {
              (el as HTMLElement).classList.add('d-none');
            }
          }
        });
      }
    }
  }

  updated(): void {
    this.setSelectedTab();
  }

  setNewSelectedTab(index: number): void{
    this.selectedTab = index;
    this.setSelectedTab();
  }

  private setTabItemData(classList: DOMTokenList): void{
    if (classList[0] === 'tab-item-widget') {
      const widgetChild = this.widget.subWidgets.find((e) => e.uid === classList[1]);
      if (widgetChild) {
        const payload = JSON.parse(widgetChild.payload || '{}');
        const preload = payload.preload === undefined ? true : payload.preload;
        if (!preload && !this.loadedWidgets.includes(widgetChild.uid)) {
          this.loadedWidgets.push(widgetChild.uid);
          const storeName = this.getStoreName(widgetChild);
          let pathStore = '';
          if (!this.standAlone && storeName) {
            pathStore = WidgetHelper
              .widgetStorePath('WidgetDispatcherStore', storeName);
          }
          if (pathStore !== '') {
            this.$store.dispatch(`${pathStore}/callPreloadQuery`);
          }
        }
      }
    }
  }

  private onHidePill(widgetUid: string): void {
    if (!this.hiddenWidgets.includes(widgetUid)) {
      const widgetChildIndex = this.widget.subWidgets.findIndex((e) => e.uid === widgetUid);
      if (widgetChildIndex !== -1) {
        const widgetChild = this.widget.subWidgets[widgetChildIndex];
        if (widgetChild) {
          if (this.selectedTab === -1) {
            this.selectedTab = 0;
          }
          if (this.selectedTab === widgetChildIndex) {
            this.setNewSelectedTab(this.selectedTab + 1);
          }
          this.hiddenWidgets.push(widgetUid);
          this.widgetHidden = this.hiddenWidgets.length === this.widget.subWidgets.length;
        }
      }
    }
  }
}
